<template>
    <div>
        <form action="">
            <input placeholder="email" v-model="email" type="email"/>
            <input placeholder="password" v-model="password" type="password"/>
            <button class="auth-btn" type="button" @click="logIn">Войти в систему</button>

            <p class="another-modal" @click="closeOneOpenOtherFn('login', 'register')">Нет аккаунта? Зарегистрируйтесь!</p>

            <p class="error-text">{{error && error}}</p>
        </form>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {auth} from "@/firebase";
import {signInWithEmailAndPassword} from "firebase/auth"
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import {closeModalWindow, closeOneOpenOther} from "@/layout/modalWindowsLogic/modalWindowFns";
import {setIdAndEmailState} from "@/components/auth/authFns";

defineOptions({
    name: 'SignIn'
})

let email = ref('')
let password = ref('')

let error = ref('')

const modalWindowsStore = useModalWindowsStore()

// async
const logIn = async (e) => {
    e.preventDefault()

    // await
    signInWithEmailAndPassword(auth, email.value, password.value)
        .then(async (user) => {
          console.log(user)

          // let token = await API(false, 'log-in', 'auth-logic', 'POST', null, `log-in/${email.value}`)
          // localStorage.setItem("chatClonerToken", token);

          // логика логирования
          // отправляем запрос на сервер
          // по email делаем новый токен в строчку и возвращаем его сюда, чтобы в localStorage прописать

          error.value = ''
          password.value = ''
          email.value = ''

          closeModalWindow('login', modalWindowsStore)

          // console.log('signInWithEmailAndPassword')

          await setIdAndEmailState()
        }).catch((e) => {
            if (e.message !== undefined) error.value = e.message
            else error.value = 'Sorry - could not find your account!'
        })
}

const closeOneOpenOtherFn = (closeModal, openModal) => {
    closeOneOpenOther(closeModal, openModal, modalWindowsStore)
}
</script>

<style lang="scss" scoped>

</style>
