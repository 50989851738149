import { defineStore } from 'pinia'

export const useMainStore = defineStore('mainStore', {
    state: () => {
        return {
            menuItems: [],
            showMobileMenu: false,
            isDev: false,

            paymentId: 'no'
        }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    actions: {
        toggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu
        },
        closeMobileMenu() {
            this.showMobileMenu = false
        },
        setIsDev(value) {
            this.isDev = value
        },
        setPaymentId(value) {
            this.paymentId = value
        },
        setMenuItems(value) {
            this.menuItems = value
        },
    },
    // getters: {
    //     showOverlayGetter: (state) => state.showOverlay,
    // },
    persist: true,
})
