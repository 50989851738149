<template>
  <div class="menu">
    <div class="menu__items">
        <router-link :to="{ name: menuItem.name}" class="menu-item" v-for="menuItem in menuItems" @click="closeMobileMenu">
            <i :class="menuItem.icon" aria-hidden="true"></i> {{ menuItem.text }}
        </router-link>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";
import {useMainStore} from "@/store/mainStore";

defineOptions({
    name: 'MenuItems'
})

const closeMobileMenu = () => {
  useMainStore().closeMobileMenu()
}

const menuItems = computed(() => useMainStore().menuItems)

useMainStore().setMenuItems([
    {name: 'indexPage', text: 'Главная', icon: 'fa fa-address-card'},
    {name: 'newspage', text: 'Новости сервиса', icon: 'fa-regular fa-newspaper'},
    {name: 'profilesPage', text: 'Профиль', icon: 'fa fa-bar-chart'},
    {name: 'autowatching', text: 'Авто-клоннер каналов', icon: 'fa fa-check-square'},
    {name: 'basecloner', text: 'Клоннер каналов', icon: 'fa-solid fa-check'},
])
</script>

<style lang="scss" scoped>
@import '@/assets/styles/media.scss';

.menu {
    width: 250px;
    background: var(--dark-color);
    overflow: auto;
    color: white;
    padding: 0 1rem;

    @include mobile {
      height: auto;
      width: inherit;
		}

    &__items {
        list-style: none;
        display: flex;
        flex-direction: column;
    }

    &-item {
        margin-top: 1rem;
        display: block;

        @include mobile {
          margin: 0;
          padding: 10px;
        }
    }
}

a.router-link-active {
    color: var(--light-color);

    i {
        background-color: var(--dark-color);
        color: #1c70a7;
        width: 30px;
    }
}

a {
    text-decoration: none;
    color: #687a8e;

    i {
        width: 30px;
    }
}
</style>
