<template>
    <div class="w-100-flex mg-t-100"></div>

    <div class="w-100-flex">
      <div class="test-div">
        <h1 style="margin: 0 0 12px 0">О приложении:</h1>

        <p>Пожалуй лучший сервис по клонированию каналов на просторах интернета: из очевидных плюсов
          <ul class="ur-ul">
            <li>свежесть выпуска софта</li>
            <li>простота usability</li>
            <li>четкий и плогически понятный интерфейс</li>
            <li>скорость копирования каналов и бесперебойная работа</li>
            <li> 24/7 техническая поддержка и постоянная разработка, улучшение, оптимизация кода под руководством опытных профессионалов данной сферы</li>
          </ul>
        </p>
      </div>
    </div>

    <template v-if="isTestCrudUserLogic">
        <div class="w-100-flex">
          <div class="test-div mg-t-10">
            <h3>Test crud users logic</h3>

            <div class="w-100-flex flex-column">
              <div class="test-logic-post-endpoint mg-a-0-1">
                  <div>POST/users - default values current user</div>

                  <div @click="makeUserDefaultByUserId">test</div>
              </div>

              <div class="test-logic-post-endpoint mg-a-0-1">
                  <div>POST/users - create user</div>

                  <div @click="createUser">test</div>
              </div>

              <div class="test-logic-get-endpoint mg-a-0-1">
                  <div>GET/users - get all users</div>

                  <div @click="getAllUsers">test</div>
              </div>

              <div class="test-logic-get-endpoint mg-a-0-1">
                  <div>GET/user-by-id - get user by id</div>

                  <div @click="getUserById">test</div>
              </div>

              <div class="test-logic-get-endpoint mg-a-0-1">
                  <div>GET/user-by-email - get user by email</div>

                  <div @click="getUserByEmail">test</div>
              </div>

              <div class="test-logic-delete-endpoint mg-a-0-1">
                  <div>DELETE/user-by-id - delete user by id</div>

                  <div @click="deleteUserById">test</div>
              </div>

              <div class="test-logic-delete-endpoint mg-a-0-1">
                  <div>DELETE/user-by-email - delete user by email</div>

                  <div @click="deleteUserByEmail">test</div>
              </div>

              <div class="test-logic-put-endpoint mg-a-0-1">
                  <div>PUT/update-all-user-data - update all user data</div>

                  <div @click="updateAllUserData">test</div>
              </div>

              <div class="test-logic-put-endpoint mg-a-0-1">
                  <div>PUT/update-user-email - update user email</div>

                  <div @click="updateUserEmail">test</div>
              </div>
          </div>
          </div>
        </div>
    </template>
</template>

<script setup>
import {ref} from "vue";

import {API} from "@/services/API";
import {chatClonerLogic} from "@/services/chatClonerLogic";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import {showModal} from "@/layout/modalWindowsLogic/modalWindowFns";
import {useAuthState} from "@/components/auth/authState";
import {setIdAndEmailState} from "@/components/auth/authFns";


defineOptions({
    name: 'indexPage'
})

let isTestCrudUserLogic = ref(false)

if (process.env.NODE_ENV === 'development') isTestCrudUserLogic.value = true

const createUser = async () => {
    await API(true, 'create_user','users', 'POST', {
        "email": 'test',
        "user_id": '12345', // should always be unique
        "ref_link": '',
        "last_online": '',
        "ref_id": 'test',
        "password": 'test',
        "token": '',
        "tg_api": 'no tg api',
        "channels_source": '0',
        "to_copy_in_channel_id": 0,
        "client_payment_status": "need payment",
        "payment_data": "0",
        "next_payment_date": '0',
        "payment_id": 'no'
    }, 'users')
}

const makeUserDefaultByUserId = async () => {
      const authState = useAuthState()

      await API(true, 'make_user_default','users', 'POST', {
        "user_id": 'test',
        "ref_link": '',
        "last_online": '',
        "password": '111111',
        "token": '',
        "tg_api": 'no tg api',
        "channels_source": '0',
        "to_copy_in_channel_id": 0,
        "client_payment_status": "need payment",
        "payment_data": "0",
        "next_payment_date": '0',
        "payment_id": 'no'
    }, 'user-default')

    await setIdAndEmailState()
}

const getAllUsers = async () => {
    let res = await API(true, 'get_all_users','users', 'GET', null, 'users')

    console.log(res)
}

const getUserById = async () => {
    let res = await API(true, 'get_user_by_id','users', 'GET', null, 'user-by-id/13')

    console.log(res)
}

const getUserByEmail = async () => {
    let res = await API(true, 'get_user_by_email','users', 'GET', null, 'user-by-email/test2@gmail.com')

    console.log(res)
}

const deleteUserById = async () => {
    let res = await API(true, 'delete_user_by_id','users', 'DELETE', null, 'user-by-id/23')

    console.log(res)
}

const deleteUserByEmail = async () => {
    let res = await API(true, 'delete_user_by_email','users', 'DELETE', null, 'user-by-email/test2@gmail.com')

    console.log(res)
}

const updateAllUserData = async () => {
    let res = await API(true, 'update_all_user_data','users', 'PUT', {
        "email": 'test12323',
        "user_id": 'test12323',
        "ref_link": '44444444',
        "last_online": '',
        "ref_id": 'oppp',
        "password": 'dfgdfgd',
        "token": ''
    }, 'update-all-user-data/13')

    console.log(res)
}

const updateUserEmail = async () => {
    let res = await API(true, 'update_user_email','users', 'PUT', {
        "email": 'newEmail@gmail.com',
    }, 'update-user-email/13')

    console.log(res)
}

// #####################################
// #####################################
// #####################################
</script>

<style lang="scss">
/* test logic styles */

.test-logic-post-endpoint,
.test-logic-get-endpoint,
.test-logic-delete-endpoint,
.test-logic-put-endpoint,
.test-logic-endpoint {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.test-logic-post-endpoint {
    background-color: var(--color-active);
}

.test-logic-get-endpoint {
    background-color: #a7d8fc;
}

.test-logic-delete-endpoint {
    background-color: #f1626c;
}

.test-logic-put-endpoint {
    background-color: #f1aa62;
}

.test-logic-endpoint {
    background-color: #cd6efc;
}

.ur-ul {
  margin-top: 20px;

  > li {
    margin-left: 20px;
    list-style: square;
  }
}
</style>
