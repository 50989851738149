import { createRouter, createWebHistory } from 'vue-router'
import indexPage from "@/views/indexPage/indexPage.vue";

import profilesPage from "@/views/profilesPage/profilesPage.vue";

import clonerPageWatching from "@/views/clonerPage/clonerPageWatching.vue";
import clonerPageBase from "@/views/clonerPage/clonerPageBase.vue";

import newsPage from "@/views/newsPage/newsPage.vue";

const routes = [
  {
    path: '/',
    name: 'indexPage',
    component: indexPage
  },
  {
    path: '/profiles',
    name: 'profilesPage',
    component: profilesPage
  },
  {
    path: '/autowatching',
    name: 'autowatching',
    component: clonerPageWatching
  },
  {
    path: '/basecloner',
    name: 'basecloner',
    component: clonerPageBase
  },
  {
    path: '/news',
    name: 'newspage',
    component: newsPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
