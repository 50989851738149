<template>
    <div class="success-modal">
        <b>Info:</b> {{ successStatus.text }}
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";

const modalWindowsStore = useModalWindowsStore()


const successStatus = computed(() => modalWindowsStore.successStatus)
</script>

<style scoped>
.success-modal {
    background: var(--light-color);
    padding: 30px;
    border: 3px solid #319126;
    color: #319126;
}
</style>
