import { defineStore } from 'pinia'

export const useModalWindowsStore = defineStore('modalWindowsStore', {
    state: () => {
        return {
            showOverlay: false,
            showLoginModal: false,
            showRegisterModal: false,
            showErrorModal: false,
            showSuccessModal: false,
            errorStatus: {
                text: ''
            },
            successStatus: {
                text: ''
            },
        }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    actions: {
        setShowOverlay(value) {
            this.showOverlay = value
        },
        setShowLoginModal(value) {
            this.showLoginModal = value
        },
        setShowRegisterModal(value) {
            this.showRegisterModal = value
        },
        setShowErrorModal(value) {
            this.showErrorModal = value
        },
        setShowSuccessModal(value) {
            this.showSuccessModal = value
        },
        setErrorStatus(value) {
            this.errorStatus = value
        },
        setSuccessStatus(value) {
            this.successStatus = value
        },
    },
    // getters: {
    //     showOverlayGetter: (state) => state.showOverlay,
    // },
})
