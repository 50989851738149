<template>
    <div class="auth-div">
        <template v-if="isLoggedIn">
            <i @click="userSignOut" class="sign-in-out fa fa-sign-out" aria-hidden="true"></i>
        </template>

        <template v-else>
            <i @click="showLoginModal('login')" class="sign-in-out fa fa-sign-in" aria-hidden="true"></i>
        </template>
    </div>
</template>

<script setup>
import {auth} from "@/firebase";
import {computed, ref} from "vue";
import {signOut } from "firebase/auth";
import {showModal} from "@/layout/modalWindowsLogic/modalWindowFns";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import {useAuthState} from "@/components/auth/authState";
import {API} from "@/services/API";
import {setIdAndEmailState} from "@/components/auth/authFns";

defineOptions({
    name: 'AuthDetails'
})

let authUser = ref(null)

const authState = useAuthState()
const isLoggedIn = computed(() => authState.isLoggedIn)

auth.onAuthStateChanged(async (user) => {
    if (user){
        authUser.value = user

        authState.setLoggedInState(true)
        authState.setEmailState(user.email)

        // console.log('onAuthStateChanged')

        await setIdAndEmailState()
    } else {
        authUser.value = null

        authState.setLoggedInState(false)
        authState.setEmailState('')
    }
});

const userSignOut = async () => {
    // логика разлогирования
    // отправляем запрос на сервер
    // по email делаем новый секретный token в строчку (из .env) + случано сгенерированная строчка

    await API(false, 'log-out', 'auth-logic', 'POST', null, `log-out/${authState.email}`)

    signOut(auth)
        .then(() => {
          console.log('log out')
        })
        .catch((e) => console.log(e))
}

const showLoginModal = (openModal) => {
    showModal(openModal, useModalWindowsStore())
}
</script>

<style lang="scss" scoped>
.sign-in-out {
    background: var(--light-color);
    color: var(--primary-color);
    padding: 0.5rem;

  &:hover {
    background: #bac8e5;
    cursor: pointer;
  }

  // для устройств с touch экраном
  &:active {
    background: #bac8e5;
    cursor: pointer;
  }
}

.auth-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    color: var(--light-color);
}
</style>
