import {API} from "@/services/API";
import {tg_rss_lib} from "@/services/tg_rss_lib";

let intervals = {}

export const chatClonerLogic = async (chatClonerLogicPayload, infiniteChannelWatching, intervalInfo= {
    deleteInterval: false,
    executionInterval: 0
}) => {
    if (intervalInfo.deleteInterval){
        clearInterval(intervals[chatClonerLogicPayload.sourceChannel])

        delete intervals[chatClonerLogicPayload.sourceChannel]

        return await API(
            false,
            'stopInfiniteChannelWatching',
            'some-module',
            'POST',
            {sourceChannel: chatClonerLogicPayload.sourceChannel},
            'stopInfiniteChannelWatching'
        )
    }

    if ([chatClonerLogicPayload.sourceChannel, chatClonerLogicPayload.toCopyInChannel].some(i => i === '')){
        return {error: 'вы забыли указать источник откуда копировать информацию или канал куда копировать информацию'}
    }

    let firstIteration = true
    // нужен только в случае doInfiniteChannelWatching

    if (infiniteChannelWatching){
        intervals[chatClonerLogicPayload.sourceChannel] = setInterval(async () => {
            await doChatClonerLogic()
            // for debugging issues
            // console.log('interval ', chatClonerLogicPayload.sourceChannel, intervals, intervalInfo.executionInterval)
        }, intervalInfo.executionInterval)

        console.log(intervals)
        return 200
    } else {
        await doChatClonerLogic()
        return 200
    }

    async function doChatClonerLogic(){
        let rawData
        let resultAfterTgRssLib

        // вначале идет этот запрос - авто клонирование или нет - неважно - нужны данные в любом случае
        rawData = await API(false, 'getRawDataFromRequest','some-module', 'POST', {
            sourceChannel: chatClonerLogicPayload.sourceChannel,
            infiniteChannelWatching: infiniteChannelWatching,
            postLimit: chatClonerLogicPayload.postLimit,
            userId: chatClonerLogicPayload.userId

        }, 'getRawDataFromRequest')

        if (rawData === 300){
            return {error: "У вас закочилась подписка - оплатите новый месяц!"}
        }

        console.log('rawData', rawData)

        // проверка на то существует ли каналы вообще
        // [chatClonerLogicPayload.sourceChannel, chatClonerLogicPayload.toCopyInChannel]

        let sendData = {
            sourceChannel: chatClonerLogicPayload.sourceChannel,
            rawData: rawData
        }

        let resFirstPostInDay = await tg_rss_lib({...sendData, firstPostInDay: true})

        if (resFirstPostInDay?.error) return {error: resFirstPostInDay.error}

        let resSendData = await tg_rss_lib(sendData)

        if (resSendData?.error) return {error: resSendData.error}

        const extractText = (text, targetString, beforeOrAfter) => {
            const index = text.indexOf(targetString);
            if (index !== -1) {
                if (beforeOrAfter === 'after') return text.substring(index + targetString.length);
                if (beforeOrAfter === 'before') return text.substring(0, index);
            } else {
                return "No match found.";
            }
        }

        resFirstPostInDay = extractText(resFirstPostInDay, '</pubDate>', 'after')
        resFirstPostInDay = extractText(resFirstPostInDay, '</channel>', 'before')

        resSendData = extractText(resSendData, '</pubDate>', 'after')
        resSendData = extractText(resSendData, '</channel>', 'before')

        let importantStr = `<?xml version="1.0" encoding="UTF-8" ?><rss version="2.0"><channel><title>${chatClonerLogicPayload.sourceChannel}</title><link>https://t.me/${chatClonerLogicPayload.sourceChannel}</link><description>RSS Feed from ${chatClonerLogicPayload.sourceChannel} Telegram channel</description><pubDate></pubDate>`

        resultAfterTgRssLib = importantStr + resFirstPostInDay + resSendData + '</channel></rss>'

        // без important str - где есть теги description, channel, rss, xml не работает строчка ниже
        // resultAfterTgRssLib = resFirstPostInDay + resSendData

        // изначальные варианты: без первого поста в день, либо только первый пост в день
        // resultAfterTgRssLib = resSendData
        // resultAfterTgRssLib = resFirstPostInDay

        console.log(resultAfterTgRssLib, 'result')

        let res = async(route) => {
            await API(false, route, 'some-module', 'POST', {
                ...chatClonerLogicPayload,
                resultAfterTgRssLib: resultAfterTgRssLib,
                firstIteration: firstIteration // нужен только в случае doInfiniteChannelWatching - в случае doChatClonerLogic просто пофиг на это дело
            }, route)
        }

        if (infiniteChannelWatching){
            await res('startInfiniteChannelWatching')

            firstIteration = false

            return res
        }

        return await res('doChatClonerLogic')
    }
}
