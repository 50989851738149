import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";

export const closeModalWindow = (modalWindow, modalWindowsStore) => {
    modalWindowsStore.setShowOverlay(false)

    const modalWindowsCloseFunctions = {
        'login': modalWindowsStore.setShowLoginModal,
        'register': modalWindowsStore.setShowRegisterModal,
        'error': modalWindowsStore.setShowErrorModal,
        'success': modalWindowsStore.setShowSuccessModal
    }

    if (modalWindow === 'error') modalWindowsStore.setErrorStatus({
        text: '',
        apiRoute: '',
        module: ''
    })

    modalWindowsCloseFunctions[modalWindow](false)
}

export const showModal = (modalName, modalWindowsStore) => {
    modalWindowsStore.setShowOverlay(true)

    if (modalName === 'login') modalWindowsStore.setShowLoginModal(true)
    if (modalName === 'register') modalWindowsStore.setShowRegisterModal(true)
    if (modalName === 'error') modalWindowsStore.setShowErrorModal(true)
    if (modalName === 'success') modalWindowsStore.setShowSuccessModal(true)
}

export const closeOneOpenOther = (closeModal, openModal, modalWindowsStore) => {
    closeModalWindow(closeModal, modalWindowsStore)
    showModal(openModal, modalWindowsStore)
}
