<template>
    <header class="header">
        <div class="header__inner container">
            <div class="header__menu">
                <h3 class="header__menu-logo">TG-PRIME CHANNEL CLONER, v 2.0.1</h3>
            </div>

            <div class="header__auth-state">
                <span class="header__menu-bar" v-if="isLoggedIn" @click="toggleMenuMobile">&#9776;</span> <AuthDetails/>
            </div>

            <div v-if="showMobileMenu" class="mobile-menu">
              <Menu-Items/>
            </div>
        </div>
    </header>
</template>

<script setup>
import AuthDetails from "@/components/auth/AuthDetails.vue";
import {useAuthState} from "@/components/auth/authState";
import {computed, ref} from "vue";
import {useMainStore} from "@/store/mainStore";
import MenuItems from "@/layout/menu/MenuItems.vue";

defineOptions({
    name: 'NavBar'
})

const authState = useAuthState()
const mainStore = useMainStore()
const isLoggedIn = computed(() => authState.isLoggedIn)
const showMobileMenu = computed(() => mainStore.showMobileMenu)

const toggleMenuMobile = () => {
  mainStore.toggleMobileMenu()
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/media.scss';

.mobile-menu {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.header {
    background: var(--primary-color);
    position: fixed;
    width: -webkit-fill-available;

    &__menu {
        display: flex;
        align-items: center;
    }

    &__auth-state {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include mobile {
          width: 100%;
          justify-content: space-between;
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        @include mobile {
          flex-direction: column;
          align-items: baseline;
          gap: 10px;
          padding: 0.5rem;
        }
    }

    &__menu-items {
        display: flex;
        list-style: none;
        margin-left: 1rem;
    }

    &__menu-item {
        padding: 1.5rem;
    }

    &__menu-logo {
        padding-left: 0;
        font-weight: 500;
        color: var(--light-color);
    }

    &__menu-bar {
        display: none;
        padding-left: 0;
        font-weight: 500;
        font-size: 30px;
        color: var(--light-color);

        @include mobile {
          display: block;
        }
    }
}
</style>
