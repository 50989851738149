<template>
    <div style="display: flex;">
        <Menu v-if="isLoggedIn"/>

        <div class="top-section">
            <NavBar/>

            <suspense>
                <div class="container">
                    <router-view v-if="isLoggedIn"/>

                    <div v-if="!isLoggedIn">
                        <h3 class="mg-t-100">Для просмотра системы пожалуйста авторизуйтесь.</h3>
                    </div>
                </div>
            </suspense>
        </div>
    </div>

    <div class="footer">
      ООО "ПРОТОКОЛ РАССВЕТА" ИНН 6165233745 ОГРН 1226100023676 Почта для связи - sunriseprotocol@gmail.com
    </div>

  <transition name="fade">
    <modal-windows-overlay/>
  </transition>
</template>

<style lang="scss">
@import '@/assets/styles/main_styles.css';
@import '@/assets/styles/media.scss';

.top-section {
  width: 100%;
  height: 95vh;
  overflow: auto;

  @include mobile {
      height: 90vh;
  }
}

.footer {
  background: var(--primary-color);
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  text-align: center;

  @include mobile {
      height: 10vh;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>

<script setup>
import NavBar from "@/layout/navbar/NavBar.vue";
import ModalWindowsOverlay from "@/layout/modalWindowsLogic/modalWindowsOverlay.vue";
import {useAuthState} from "@/components/auth/authState";
import {computed} from "vue";
import Menu from "@/layout/menu/Menu.vue";
import {useMainStore} from "@/store/mainStore";

const authState = useAuthState()
const isLoggedIn = computed(() => authState.isLoggedIn)

if (process.env.NODE_ENV === 'development') useMainStore().setIsDev(true)
</script>
