<script setup>
import SignIn from "@/components/auth/SignIn.vue";
import SignUp from "@/components/auth/SignUp.vue";

defineOptions({
    name: 'AuthModalWrapper',
})

const props = defineProps(['modalTitle'])

const {
    modalTitle
} = props

</script>

<template>
  <div class="login-register-wrapper">
      <div class="close-modal">
          <span @click="$emit('closeModal')">&#10005;</span>
      </div>
      <span class="modal-title">{{modalTitle}}</span>
      <hr class="hr-modal"/>

      <div class="content-modal">
          <template v-if="modalTitle === 'Вход в личный кабинет'">
              <SignIn/>
          </template>

          <template v-if="modalTitle === 'Регистрация'">
              <SignUp/>
          </template>
      </div>
  </div>
</template>

<style>
input[type=text], input[type=password], input[type=email], input[type=number] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.another-modal {
    display: flex;
    margin-top: 1rem;
    background: var(--color-active);
    padding: 0.5rem;
    border-radius: 0 10px 0 10px;
}

.another-modal:hover {
    cursor: pointer;
}

.error-text {
    color: red;
}
</style>

<style lang="scss" scoped>
.login-register-wrapper {
  background: #ffffff;
  width: 350px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.close-modal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modal-title {
  color: var(--primary-color);
  font-size: 23px;
  font-weight: 600;
}

.hr-modal {
  width: 100%;
  height: 2px;
  margin-top: 10px;
}

.content-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  align-items: center;
  padding-bottom: 30px;
}
</style>
