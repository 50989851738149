<template>
    <div class="test-div mg-t-100" v-if="client_payment_status === 'шекели уплачены'">
      <h2>Клонирование с автослежением</h2>

      <div class="usual-border mg-t-b-1 p-all-1">
          <div v-for="item in channels_source" class="auto-watching-channels-info">
            {{ item }}

            <button class="stop-auto-watching bad-button" v-if="channelsWatchingByUserEmail[userEmail].includes(item)" @click="() => {
              chatClonerLogicInit(true, item)
              channelClonerState.cleanWatchingChannelByUserEmail({email: userEmail, channel: item})
            }">
              stop
            </button>

            <span class="no-auto-watching" v-else>
              авто слежение за выходом постов не запущено
            </span>
          </div>
      </div>

      <!-- вынести потом отдельно компонентом -->
      <div class="usual-border mg-t-b-1 p-all-1">
        <h4>откуда клонируем посты (имя канала)</h4>
        <h4 v-if="isDev">примеры: funnyitmemes38, funnyitmemes38again, notariatirk, lobbywork, poleznayarabotavsegda</h4>

        <template v-for="source in channels_source" :key="source">
          <p><input type="radio" :value="source" v-model="chatClonerLogicPayload.sourceChannel"/> {{source}}</p>
        </template>
      </div>

      <!-- вынести потом отдельно компонентом -->
      <div class="usual-border mg-t-b-1 p-all-1">
        <h4>куда клонируем посты (id канала)</h4>
        <h4 v-if="isDev">пример 770846692</h4>
        <input type="text" :placeholder="to_copy_in_channel_id" disabled>
      </div>

      <!-- вынести потом отдельно компонентом -->
      <div class="usual-border mg-t-b-1 p-all-1">
        <input type="checkbox" v-model="chatClonerLogicPayload.withImages"> - брать ли изображения из постов или нет
      </div>

      <div class="usual-border mg-t-b-1 p-all-1">
        <input type="number" v-model="watchChannelIntervalNumber" min="0">

        <select v-model="watchChannelIntervalUnit">
          <option value="second" selected>секунда</option>
          <option value="minute">минута</option>
          <option value="hour">час</option>
          <option value="day">день</option>
        </select>

        - выберите интервал проверки выхода последнего поста

        {{watchChannelIntervalMilliseconds}}
      </div>

      <div class="usual-border mg-t-b-1 p-all-1" v-if="isDev">{{chatClonerLogicPayload}}</div>

      <button @click="chatClonerLogicInit">Send</button>
    </div>

    <div class="test-div mg-t-100" v-else>
      Требуется оплата функционала
    </div>
</template>

<script setup>
import {chatClonerLogic} from "@/services/chatClonerLogic";
import {computed, ref, watch} from "vue";
import {showModal} from "@/layout/modalWindowsLogic/modalWindowFns";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import {useAuthState} from "@/components/auth/authState";
import {useChannelClonerState} from "@/store/channelClonerState";
import {useMainStore} from "@/store/mainStore";
import {isTodayMoreOrSameThanPaymentDate} from "@/services/timeFns";
import {API} from "@/services/API";

defineOptions({
    name: 'autowatching'
})

const chatClonerLogicPayload = ref({
    sourceChannel: '',
    toCopyInChannel: '',
    withImages: true,
    tgApi: '',
    userId: 0
})

const watchChannelIntervalNumber = ref(0)
const watchChannelIntervalUnit = ref('second')

const channelClonerState = useChannelClonerState()
const modalWindowsStore = useModalWindowsStore()
const authState = useAuthState()
const mainStore = useMainStore()

const userId = computed(() => authState.id)

const channels_source = computed(() => authState.channels_source)
const to_copy_in_channel_id = computed(() => authState.to_copy_in_channel_id)
const isDev = computed(() => mainStore.isDev)
const tgApi = computed(() => authState.tg_api)
const payment_data = computed(() => authState.payment_data)
const client_payment_status = computed(() => authState.client_payment_status)

const watchChannelIntervalMilliseconds = computed(() => {
  let unit = 1000
  if (watchChannelIntervalUnit.value === 'second') unit = 1000
  if (watchChannelIntervalUnit.value === 'minute') unit = 60000
  if (watchChannelIntervalUnit.value === 'hour') unit = 60000 * 60
  if (watchChannelIntervalUnit.value === 'day') unit = 60000 * 60 * 24
  return watchChannelIntervalNumber.value * unit
})

const channelsWatchingByUserEmail = computed(() => channelClonerState.channelsWatchingByUserEmail)
const userEmail = computed(() => authState.email)

chatClonerLogicPayload.value.toCopyInChannel = to_copy_in_channel_id.value
chatClonerLogicPayload.value.userId = userId.value
channelClonerState.channelsWatchingByUserEmail[userEmail.value] = []
chatClonerLogicPayload.value.tgApi = tgApi.value

const today = new Date();
const formattedToday = today.toISOString().split('T')[0];

// todo - sameSame
const updatePaymentData = async () => {
  // before query - update payment_data on front

  let res = await API(true, 'update_user_payment_data','users', 'PUT', {
      "payment_data": JSON.stringify(payment_data.value),
  }, `update-user-payment-data/${userId.value}`)

  if (isDev) console.log(res, 'payment_data updated')
}

const chatClonerLogicInit = async (deleteInterval = false, item=null) => {
    if (payment_data.value[chatClonerLogicPayload.value.sourceChannel] === undefined){
      useModalWindowsStore().setErrorStatus({text: 'Канал добавлен в список источников но не оплачен!'})
      showModal('error', useModalWindowsStore())
      return
    }

    if ([true, 'same'].includes(isTodayMoreOrSameThanPaymentDate(formattedToday, payment_data.value[chatClonerLogicPayload.value.sourceChannel]))){
      delete payment_data.value[chatClonerLogicPayload.value.sourceChannel]
      await updatePaymentData()

      useModalWindowsStore().setErrorStatus({text: 'Подошел период оплаты канала - пожалуйста продлите платеж!'})
      showModal('error', useModalWindowsStore())
      return
    }

    if (deleteInterval === true){
      let result = await chatClonerLogic({
        sourceChannel: item
      }, null, {
        deleteInterval: true,
        executionInterval: 0
      })

      console.log(result)

      return 200
    }

    if (!channels_source.value.some(i => i === chatClonerLogicPayload.value.sourceChannel)){
      useModalWindowsStore().setErrorStatus({text: 'Можно выбрать только доступные каналы!'})
      showModal('error', useModalWindowsStore())
      return
    }

    if (watchChannelIntervalMilliseconds.value === 0){
      useModalWindowsStore().setErrorStatus({text: 'Интервал не может быть равен нулю!'})
      showModal('error', useModalWindowsStore())
      return
    }

    // убираем ссылочную зависимость
    let res = await chatClonerLogic(JSON.parse(JSON.stringify(chatClonerLogicPayload.value)), true, {
      deleteInterval: false,
      executionInterval: watchChannelIntervalMilliseconds.value
    })

    if (res?.error) {
      modalWindowsStore.setErrorStatus({text: res.error})
      showModal('error', useModalWindowsStore())
    } else {
      console.log(res, 'какой-то res!')

      channelClonerState.setChannelsWatchingByUser({email: authState.email, channel: chatClonerLogicPayload.value.sourceChannel})
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/media.scss';

.auto-watching-channels-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    border: 1px dotted black;
    margin: 4px 0;

    @include mobile {
      display: block;
      padding: 4px;
		}
}

.no-auto-watching, .stop-auto-watching {
    @include mobile {
      display: block;
      margin-top: 4px;
		}
}
</style>
