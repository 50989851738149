import { defineStore } from 'pinia'

export const useAuthState = defineStore('authState', {
    state: () => {
        return {
            isLoggedIn: false,
            email: '',
            id: 0,
            tg_api: '',
            channels_source: '0',
            to_copy_in_channel_id: 0,
            client_payment_status: '',
            payment_data: '',
            next_payment_date: '0'
        }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    actions: {
        setLoggedInState(value) {
            this.isLoggedIn = value
        },
        setEmailState(value) {
            this.email = value
        },
        setIdState(value) {
            this.id = value
        },
        setTgApiState(value) {
            this.tg_api = value
        },
        setChannelsSourceState(value) {
            this.channels_source = value
        },
        setToCopyInChannelIdState(value) {
            this.to_copy_in_channel_id = value
        },
        setClientPaymentStatusState(value) {
            this.client_payment_status = value
        },
        setPaymentDataState(value) {
            this.payment_data = value
        },
        setDaysToNextPaymentState(value) {
            this.next_payment_date = value
        },
    },
    // getters: {
    //     showOverlayGetter: (state) => state.showOverlay,
    // },
})
