import {API} from "@/services/API";
import {useAuthState} from "@/components/auth/authState";
import {checkSomething} from "@/services/checkSomething";

export const setIdAndEmailState = async () => {
      let userInfo = await API(true, 'get_user_by_email','users', 'GET', null, `user-by-email/${useAuthState().email}`)

      if (userInfo?.error) return

      useAuthState().setIdState(userInfo.id)
      useAuthState().setTgApiState(userInfo.tg_api)

      if (typeof(userInfo.channels_source) === 'number'){
            useAuthState().setChannelsSourceState(String(JSON.parse(userInfo.channels_source)))
      } else {
            useAuthState().setChannelsSourceState(JSON.parse(userInfo.channels_source))
      }

      useAuthState().setToCopyInChannelIdState(userInfo.to_copy_in_channel_id)
      useAuthState().setClientPaymentStatusState(userInfo.client_payment_status)
      useAuthState().setPaymentDataState(JSON.parse(userInfo.payment_data))
      useAuthState().setDaysToNextPaymentState(userInfo.next_payment_date)

      await checkSomething()
}