export const tg_rss_lib = async (data) => {
    let rawData = data.rawData

    let firstPostInDay = false

    if (data.firstPostInDay !== undefined) firstPostInDay = true

    const VOID_TAGS = [
        'area',
        'base',
        'br',
        'hr',
        'img',
        'input',
        'link',
        'meta',
        'param',
        'command',
        'keygen',
        'source'
    ];

    function html_parser(input) {
        const root = {children: []};

        function regex_parser(regex, handler = () => {
        }) {
            const match = regex.exec(input);
            if (match !== null) {
                const [full_match, ...captures] = match;
                input = input.substr(full_match.length);
                handler(...captures);
                return true;
            } else {
                return false;
            }
        }

        function content_parser(cursor) {
            let run = true;
            while (run && input.length > 0) {
                // Parse an open tag
                const success = regex_parser(/^<([a-zA-Z][a-zA-Z0-9\-]*)/, tag => {
                        const new_tag = {tag, attributes: {}, children: []};
                        cursor.children.push(new_tag);
                        attributes_parser(new_tag);
                        if (!VOID_TAGS.includes(tag.toLowerCase())) {
                            content_parser(new_tag);
                        }
                    })
                    || regex_parser(/^<!--((?:[^-]|-(?!->))*)-->/, comment => {
                        cursor.children.push({comment});
                    })
                    || regex_parser(/^<\/([a-zA-Z][a-zA-Z0-9\-]*)>/, tag => {
                        if (cursor.tag !== tag) {
                            //throw new Error("Unmatched close tag")
                            return {error: 'Unmatched close tag'};
                        }
                        run = false;
                    })
                    // Parse a text node
                    || regex_parser(/^([^<]+)/, text => {
                        cursor.children.push({
                            text
                        });
                    });

                if (!success) {
                    //throw new Error("Parse error")
                    return {error: 'Parse error'};
                }
            }
        }

        function attributes_parser(cursor) {
            while (regex_parser(/^\s+([a-zA-Z][a-zA-Z0-9\-]+)="([^"]*)"/, (name, value) => {
                cursor.attributes[name] = value;
            })) {
            }
            if (!regex_parser(/^\s*>/)) {
                //throw new Error("Malformed open tag")
                return {error: 'Malformed open tag'};
            }
        }

        content_parser(root);
        return root.children;
    }

    function telegram_scraper() {
        let temp = rawData;
        if (temp.indexOf('<section class="tgme_channel_history js-message_history">') === -1)
            return 'Unknown telegram channel';
        temp = temp.split('<section class="tgme_channel_history js-message_history">');
        temp = temp[1].split('</section>');

        if (firstPostInDay) temp = temp[0].split('<div class="tgme_widget_message_wrap js-widget_message_wrap date_visible">');
        else temp = temp[0].split('<div class="tgme_widget_message_wrap js-widget_message_wrap">');

        // первое сообщение в день не парсит -
        // tgme_widget_message_wrap js-widget_message_wrap date_visible - с таким классом

        temp.splice(0, 1);
        let data = [];
        for (let i = 0; i < temp.length; i++) {
            let html = '<div class="tgme_widget_message_wrap js-widget_message_wrap">' + temp[i];
            let arr = html_parser(html);
            let message_text = '';
            let message_photo = [];
            let message_video = [];
            let views = '';
            let datetime = '';
            let include = true;

            function message_extractor(input) {
                let msg = '';

                function loop(arr) {
                    for (let k = 0; k < arr.length; k++) {
                        if (arr[k].text != null)
                            msg += arr[k].text;
                        try {
                            if (arr[k].attributes.class === "tgme_widget_service_strong_text")
                                include = false;
                        } catch {
                        }
                        if (arr[k].children != null) {
                            if (arr[k].children.length > 0)
                                loop(arr[k].children);
                        }
                    }
                }

                loop(input);
                return msg.replaceAll('/>', ' ');
            }

            for (let j = 0; j < arr[0].children[0].children[3].children.length; j++) {
                let child_class = '';
                try {
                    child_class = arr[0].children[0].children[3].children[j].attributes.class;
                } catch {
                }
                if (child_class === "tgme_widget_message_text js-message_text" || child_class === "tgme_widget_message_text js-message_text before_footer") {
                    let message_node = arr[0].children[0].children[3].children[j].children;
                    message_text = message_extractor(message_node);
                }
                let arr_child_class = child_class.split(" ");
                if (arr_child_class[0] === "tgme_widget_message_photo_wrap") {
                    message_photo.push(arr[0].children[0].children[3].children[j].attributes.style.split("'")[1]);
                }
                if (child_class === "tgme_widget_message_footer compact js-message_footer") {
                    let get_views = arr[0].children[0].children[3].children[j].children[1].children[1].children[0].text;
                    if (get_views != null)
                        views = get_views;
                    try {
                        datetime = arr[0].children[0].children[3].children[j].children[1].children[3].children[0].children[0].attributes.datetime;
                    } catch {
                    }
                    try {
                        datetime = arr[0].children[0].children[3].children[j].children[1].children[3].children[2].children[0].attributes.datetime;
                    } catch {
                    }
                }
                if (child_class === "tgme_widget_message_video_player blured js-message_video_player") {
                    message_video.push(arr[0].children[0].children[3].children[j].children[4].children[1].attributes.src);
                }
                if (child_class === "tgme_widget_message_video_player js-message_video_player") {
                    message_video.push(arr[0].children[0].children[3].children[j].children[2].children[1].attributes.src);
                }
                if (child_class === "media_supported_cont") {
                    try {
                        let src = arr[0].children[0].children[3].children[j].children[0].children[2].attributes.src;
                        if (src != null)
                            message_video.push(src);
                    } catch {
                    }
                    try {
                        let src = arr[0].children[0].children[3].children[j].children[0].children[2].children[1].attributes.src;
                        if (src != null)
                            message_video.push(src);
                    } catch {
                    }
                    try {
                        let message_node = arr[0].children[0].children[3].children[j].children[1].children;
                        message_text = message_extractor(message_node);
                    } catch {
                    }
                }
                if (child_class === "tgme_widget_message_grouped_wrap js-message_grouped_wrap") {
                    function loop(node) {
                        for (let k = 0; k < node.length; k++) {
                            try {
                                if (node[k].attributes.class != null) {
                                    if (node[k].attributes.class === "tgme_widget_message_video js-message_video")
                                        message_video.push(node[k].attributes.src);
                                }
                            } catch {
                            }
                            try {
                                if (node[k].attributes.class != null) {
                                    if (node[k].attributes.class === "tgme_widget_message_photo_wrap grouped_media_wrap blured js-message_photo") {
                                        let photo
                                        // было изначально
                                        // let photo = node[k].attributes.style.split("'")[1];

                                        // eval('debugger')

                                        // при автоподписке почему-то прилетают одинарные кавычки, вместо &quot; - поэтому оставляем в том случае как было
                                        if (node[k].attributes.style.includes('&quot;')) photo = node[k].attributes.style.split("&quot;")[1];
                                        else photo = node[k].attributes.style.split("'")[1];

                                        message_photo.push(photo);
                                    }
                                }
                            } catch {
                            }
                            if (node[k].children != null) {
                                if (node[k].children.length > 0)
                                    loop(node[k].children);
                            }
                        }
                    }

                    let node = arr[0].children[0].children[3].children[j].children;
                    loop(node);
                }
            }
            let item = {
                //raw:arr[0],
                data_post: arr[0].children[0].attributes["data-post"],
                data_view: arr[0].children[0].attributes["data-view"],
                user_url: arr[0].children[0].children[1].children[0].attributes.href,
                user_photo: arr[0].children[0].children[1].children[0].children[0].children[0].attributes.src,
                user_name: arr[0].children[0].children[3].children[3].children[0].children[0].children[0].text,
                message_url: 'https://t.me/' + arr[0].children[0].attributes["data-post"],
                message_text,
                message_photo,
                message_video,
                views,
                datetime
            };
            if (include)
                data.push(item);
        }

        return JSON.stringify(data, null, 30);
    }

    const __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
        function adopt(value) {
            return value instanceof P ? value : new P(function (resolve) {
                resolve(value);
            });
        }

        return new (P || (P = Promise))(function (resolve, reject) {
            function fulfilled(value) {
                try {
                    step(generator.next(value));
                } catch (e) {
                    reject(e);
                }
            }

            function rejected(value) {
                try {
                    step(generator["throw"](value));
                } catch (e) {
                    reject(e);
                }
            }

            function step(result) {
                result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
            }

            step((generator = generator.apply(thisArg, _arguments || [])).next());
        });
    };

    const telegram_rss = async (channel) => {
        return __awaiter(this, void 0, void 0, function* () {
            const HtmlEntities = function () {
            };
            HtmlEntities.map = {
                "'": "&apos;",
                "<": "&lt;",
                ">": "&gt;",
                " ": "&nbsp;",
                "¡": "&iexcl;",
                "¢": "&cent;",
                "£": "&pound;",
                "¤": "&curren;",
                "¥": "&yen;",
                "¦": "&brvbar;",
                "§": "&sect;",
                "¨": "&uml;",
                "©": "&copy;",
                "ª": "&ordf;",
                "«": "&laquo;",
                "¬": "&not;",
                "®": "&reg;",
                "¯": "&macr;",
                "°": "&deg;",
                "±": "&plusmn;",
                "²": "&sup2;",
                "³": "&sup3;",
                "´": "&acute;",
                "µ": "&micro;",
                "¶": "&para;",
                "·": "&middot;",
                "¸": "&cedil;",
                "¹": "&sup1;",
                "º": "&ordm;",
                "»": "&raquo;",
                "¼": "&frac14;",
                "½": "&frac12;",
                "¾": "&frac34;",
                "¿": "&iquest;",
                "À": "&Agrave;",
                "Á": "&Aacute;",
                "Â": "&Acirc;",
                "Ã": "&Atilde;",
                "Ä": "&Auml;",
                "Å": "&Aring;",
                "Æ": "&AElig;",
                "Ç": "&Ccedil;",
                "È": "&Egrave;",
                "É": "&Eacute;",
                "Ê": "&Ecirc;",
                "Ë": "&Euml;",
                "Ì": "&Igrave;",
                "Í": "&Iacute;",
                "Î": "&Icirc;",
                "Ï": "&Iuml;",
                "Ð": "&ETH;",
                "Ñ": "&Ntilde;",
                "Ò": "&Ograve;",
                "Ó": "&Oacute;",
                "Ô": "&Ocirc;",
                "Õ": "&Otilde;",
                "Ö": "&Ouml;",
                "×": "&times;",
                "Ø": "&Oslash;",
                "Ù": "&Ugrave;",
                "Ú": "&Uacute;",
                "Û": "&Ucirc;",
                "Ü": "&Uuml;",
                "Ý": "&Yacute;",
                "Þ": "&THORN;",
                "ß": "&szlig;",
                "à": "&agrave;",
                "á": "&aacute;",
                "â": "&acirc;",
                "ã": "&atilde;",
                "ä": "&auml;",
                "å": "&aring;",
                "æ": "&aelig;",
                "ç": "&ccedil;",
                "è": "&egrave;",
                "é": "&eacute;",
                "ê": "&ecirc;",
                "ë": "&euml;",
                "ì": "&igrave;",
                "í": "&iacute;",
                "î": "&icirc;",
                "ï": "&iuml;",
                "ð": "&eth;",
                "ñ": "&ntilde;",
                "ò": "&ograve;",
                "ó": "&oacute;",
                "ô": "&ocirc;",
                "õ": "&otilde;",
                "ö": "&ouml;",
                "÷": "&divide;",
                "ø": "&oslash;",
                "ù": "&ugrave;",
                "ú": "&uacute;",
                "û": "&ucirc;",
                "ü": "&uuml;",
                "ý": "&yacute;",
                "þ": "&thorn;",
                "ÿ": "&yuml;",
                "Œ": "&OElig;",
                "œ": "&oelig;",
                "Š": "&Scaron;",
                "š": "&scaron;",
                "Ÿ": "&Yuml;",
                "ƒ": "&fnof;",
                "ˆ": "&circ;",
                "˜": "&tilde;",
                "Α": "&Alpha;",
                "Β": "&Beta;",
                "Γ": "&Gamma;",
                "Δ": "&Delta;",
                "Ε": "&Epsilon;",
                "Ζ": "&Zeta;",
                "Η": "&Eta;",
                "Θ": "&Theta;",
                "Ι": "&Iota;",
                "Κ": "&Kappa;",
                "Λ": "&Lambda;",
                "Μ": "&Mu;",
                "Ν": "&Nu;",
                "Ξ": "&Xi;",
                "Ο": "&Omicron;",
                "Π": "&Pi;",
                "Ρ": "&Rho;",
                "Σ": "&Sigma;",
                "Τ": "&Tau;",
                "Υ": "&Upsilon;",
                "Φ": "&Phi;",
                "Χ": "&Chi;",
                "Ψ": "&Psi;",
                "Ω": "&Omega;",
                "α": "&alpha;",
                "β": "&beta;",
                "γ": "&gamma;",
                "δ": "&delta;",
                "ε": "&epsilon;",
                "ζ": "&zeta;",
                "η": "&eta;",
                "θ": "&theta;",
                "ι": "&iota;",
                "κ": "&kappa;",
                "λ": "&lambda;",
                "μ": "&mu;",
                "ν": "&nu;",
                "ξ": "&xi;",
                "ο": "&omicron;",
                "π": "&pi;",
                "ρ": "&rho;",
                "ς": "&sigmaf;",
                "σ": "&sigma;",
                "τ": "&tau;",
                "υ": "&upsilon;",
                "φ": "&phi;",
                "χ": "&chi;",
                "ψ": "&psi;",
                "ω": "&omega;",
                "ϑ": "&thetasym;",
                "ϒ": "&Upsih;",
                "ϖ": "&piv;",
                "–": "&ndash;",
                "—": "&mdash;",
                "‘": "&lsquo;",
                "’": "&rsquo;",
                "‚": "&sbquo;",
                "“": "&ldquo;",
                "”": "&rdquo;",
                "„": "&bdquo;",
                "†": "&dagger;",
                "‡": "&Dagger;",
                "•": "&bull;",
                "…": "&hellip;",
                "‰": "&permil;",
                "′": "&prime;",
                "″": "&Prime;",
                "‹": "&lsaquo;",
                "›": "&rsaquo;",
                "‾": "&oline;",
                "⁄": "&frasl;",
                "€": "&euro;",
                "ℑ": "&image;",
                "℘": "&weierp;",
                "ℜ": "&real;",
                "™": "&trade;",
                "ℵ": "&alefsym;",
                "←": "&larr;",
                "↑": "&uarr;",
                "→": "&rarr;",
                "↓": "&darr;",
                "↔": "&harr;",
                "↵": "&crarr;",
                "⇐": "&lArr;",
                "⇑": "&UArr;",
                "⇒": "&rArr;",
                "⇓": "&dArr;",
                "⇔": "&hArr;",
                "∀": "&forall;",
                "∂": "&part;",
                "∃": "&exist;",
                "∅": "&empty;",
                "∇": "&nabla;",
                "∈": "&isin;",
                "∉": "&notin;",
                "∋": "&ni;",
                "∏": "&prod;",
                "∑": "&sum;",
                "−": "&minus;",
                "∗": "&lowast;",
                "√": "&radic;",
                "∝": "&prop;",
                "∞": "&infin;",
                "∠": "&ang;",
                "∧": "&and;",
                "∨": "&or;",
                "∩": "&cap;",
                "∪": "&cup;",
                "∫": "&int;",
                "∴": "&there4;",
                "∼": "&sim;",
                "≅": "&cong;",
                "≈": "&asymp;",
                "≠": "&ne;",
                "≡": "&equiv;",
                "≤": "&le;",
                "≥": "&ge;",
                "⊂": "&sub;",
                "⊃": "&sup;",
                "⊄": "&nsub;",
                "⊆": "&sube;",
                "⊇": "&supe;",
                "⊕": "&oplus;",
                "⊗": "&otimes;",
                "⊥": "&perp;",
                "⋅": "&sdot;",
                "⌈": "&lceil;",
                "⌉": "&rceil;",
                "⌊": "&lfloor;",
                "⌋": "&rfloor;",
                "⟨": "&lang;",
                "⟩": "&rang;",
                "◊": "&loz;",
                "♠": "&spades;",
                "♣": "&clubs;",
                "♥": "&hearts;",
                "♦": "&diams;"
            };
            HtmlEntities.decode = function (string) {
                const entityMap = HtmlEntities.map;
                for (const key in entityMap) {
                    const entity = entityMap[key];
                    const regex = new RegExp(entity, 'g');
                    string = string.replace(regex, key);
                }
                string = string.replace(/&quot;/g, '"');
                string = string.replace(/&amp;/g, '&');
                string = string.replaceAll('&nbsp;', ' ');
                string = string.replaceAll("&#39;", "'");
                return string;
            };
            HtmlEntities.encode = function (string) {
                const entityMap = HtmlEntities.map;
                string = string.replace(/&/g, '&amp;');
                string = string.replace(/"/g, '&quot;');
                for (const key in entityMap) {
                    const entity = entityMap[key];
                    const regex = new RegExp(key, 'g');
                    string = string.replace(regex, entity);
                }
                return string;
            };

            let data

            try {
                data = JSON.parse(telegram_scraper());
            } catch (e) {
                return {error: 'JSON ERROR!'}; // error in the above string (in this case, yes)!
            }

            let rss = '<?xml version="1.0" encoding="UTF-8" ?>\n' +
                '<rss version="2.0">\n' +
                '	<channel>\n' +
                '		<title>' + channel + '</title>\n' +
                '		<link>https://t.me/' + channel + '</link>\n' +
                '		<description>RSS Feed from ' + channel + ' Telegram channel</description>\n' +
                '		<pubDate>' + new Date().toJSON() + '</pubDate>\n';
            for (let i = 0; i < data.length; i++) {
                let title = '';
                let msg = data[i].message_text;
                // console.log(data[i], 1000)
                if (msg === '') {
                    title = data[i].data_post;
                } else {
                    if (msg.length < 250) {
                        title = msg;
                    } else {
                        let temp = msg.split(" ");
                        for (let j = 0; j < temp.length; j++) {
                            let temp_title = title + ' ' + temp[j];
                            if (temp_title.length < 250 - 4)
                                title = temp_title;
                        }
                        title += ' ...';
                    }
                }
                let description = '<![CDATA[ ' + HtmlEntities.decode(msg) + ' ]]>';
                let item = '		<item>\n' +
                    '			<title>' + HtmlEntities.decode(title) + '</title>\n' +
                    '			<link>' + data[i].message_url + '</link>\n' +
                    '			<description>' + HtmlEntities.decode(msg) + '</description>\n';
                if (data[i].message_photo.length > 0) {
                    item += '			<image>\n'

                    if (data[i].message_photo.length === 1){
                        item += '				<url>' + data[i].message_photo[0] + '</url>\n'
                    } else {
                        for (let z = 0; z < data[i].message_photo.length; z++) {
                            item += '				<url>' + data[i].message_photo[z] + '</url>\n'
                            console.log(item, data[i])
                        }
                    }

                    item += '				<title>' + HtmlEntities.decode(title) + '</title>\n' +
                        '				<link>' + data[i].message_url + '</link>\n' +
                        '			</image>\n';
                }
                item += '			<pubDate>' + data[i].datetime + '</pubDate>\n' +
                    '		</item>\n';
                rss += item;
            }
            rss += '	</channel>\n' +
                '</rss>';
            return rss;
        });
    }

    return await telegram_rss(data.channelName)
}
