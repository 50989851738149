<script setup>
import {computed} from "vue";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import AuthModalWrapper from "@/layout/modalWindowsLogic/singInSignUp/AuthModalWrapper.vue";

// не удалять
import { vOnClickOutside } from '@vueuse/components'

import ErrorsModal from "@/layout/modalWindowsLogic/errorsModal/errorsModal.vue";
import {closeModalWindow} from "@/layout/modalWindowsLogic/modalWindowFns";
import SuccessModal from "@/layout/modalWindowsLogic/successModal/successModal.vue";

defineOptions({
    name: 'modalWindowsOverlay',
})

const modalWindowsStore = useModalWindowsStore()

// computed state fields
const isOverlayShowing = computed(() => modalWindowsStore.showOverlay)
const isLoginModal = computed(() => modalWindowsStore.showLoginModal)
const isRegisterModal = computed(() => modalWindowsStore.showRegisterModal)
const isErrorModal = computed(() => modalWindowsStore.showErrorModal)
const isSuccessModal = computed(() => modalWindowsStore.showSuccessModal)

const closeModalWindowFn = (modalWindow) => {
    closeModalWindow(modalWindow, modalWindowsStore)
}
</script>

<template>
  <div class="overlay-all" v-if="isOverlayShowing">
      <AuthModalWrapper
              :modalTitle="'Вход в личный кабинет'"
              v-if="isLoginModal"
              v-on-click-outside="() => closeModalWindowFn('login')"
              @closeModal="() => closeModalWindowFn('login')"
      />

      <AuthModalWrapper
              :modalTitle="'Регистрация'"
              v-if="isRegisterModal"
              v-on-click-outside="() => closeModalWindowFn('register')"
              @closeModal="() => closeModalWindowFn('register')"
      />

      <!-- модальное окно на какие-либо ошибки -->
      <ErrorsModal
              v-if="isErrorModal"
              v-on-click-outside="() => closeModalWindowFn('error')"
      />

          <!-- модальное окно на какие-либо ошибки -->
      <SuccessModal
              v-if="isSuccessModal"
              v-on-click-outside="() => closeModalWindowFn('success')"
      />
  </div>
</template>

<style lang="scss">
.overlay-all {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1000;
  top: 0;
}
</style>
