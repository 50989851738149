export const isTodayMoreOrSameThanPaymentDate = (today, paymentDate) => {
  const todayDate = new Date(today);
  const paymentDateValue = new Date(paymentDate);

  if (todayDate > paymentDateValue) {
    return true
  } else if (todayDate < paymentDateValue) {
    return false
  } else {
    return 'same'
  }
}