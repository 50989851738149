// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDjkawgWQLOisAoAaukVJ82-Zo5DZLvQoo",
    authDomain: "chatcloner.firebaseapp.com",
    projectId: "chatcloner",
    storageBucket: "chatcloner.appspot.com",
    messagingSenderId: "181207890215",
    appId: "1:181207890215:web:5d7b7528d70bab97261102"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
