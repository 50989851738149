import { defineStore } from 'pinia'

export const useChannelClonerState = defineStore('channelClonerState', {
    state: () => {
        return {
            channelsWatchingByUserEmail: {

            }
        }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    actions: {
        setChannelsWatchingByUser({email, channel}) {
            if (this.channelsWatchingByUserEmail[email] === undefined) this.channelsWatchingByUserEmail[email] = []

            if (this.channelsWatchingByUserEmail[email].includes(channel)) return

            if (this.channelsWatchingByUserEmail[email] !== undefined) this.channelsWatchingByUserEmail[email].push(channel)
        },
        cleanWatchingChannelByUserEmail({email, channel}){
            if (this.channelsWatchingByUserEmail[email] === undefined) console.log('ошибка при поиске email пользователя!')
            // маловероятно

            this.channelsWatchingByUserEmail[email] = this.channelsWatchingByUserEmail[email].filter(i => i !== channel)
        }
    },
    // getters: {
    //     showOverlayGetter: (state) => state.showOverlay,
    // },
    persist: true,
})
