<template>
    <div class="menu-bar">
        <div style="display: flex; gap: 10px; align-items: center; margin: 20px 0; ">
            <div class="email-or-login-first-letter">{{ firstLetterOfUserEmail }}</div>

            TG-PRIME Admin
        </div>

        <h4 style="color: #405d73;">Dashboards</h4>

        <Menu-Items/>
    </div>
</template>

<script setup>

import {useAuthState} from "@/components/auth/authState";
import {computed, ref} from "vue";
import MenuItems from "@/layout/menu/MenuItems.vue";

defineOptions({
    name: 'Menu'
})

const authState = useAuthState()
const userEmail = computed(() => authState.email)

const firstLetterOfUserEmail = ref('')

firstLetterOfUserEmail.value = Array.from(userEmail.value)[0].toUpperCase()
</script>

<style lang="scss">
@import '@/assets/styles/media.scss';

.menu-bar {
    background: var(--dark-color);
    padding: 0 1rem;

    @include mobile {
      display: none;
		}
}

.email-or-login-first-letter {
    display: flex;
    border-radius: 50%;
    background-color: #1c70a7;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}
</style>
