//
// CHECK SOMETHING!
//

import {computed} from "vue";
import {useAuthState} from "@/components/auth/authState";
import {setIdAndEmailState} from "@/components/auth/authFns";
import {API} from "@/services/API";

export const checkSomething = async () => {
    const authState = useAuthState()

    const next_payment_date = computed(() => authState.next_payment_date)

    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    const makeUserRefreshByUserId = async () => {
        await API(true, 'make_user_refresh', 'users', 'POST', {
            "email": authState.email,
            "ref_link": '',
            "last_online": '',
            "tg_api": 'no tg api',
            "channels_source": '0',
            "to_copy_in_channel_id": 0,
            "client_payment_status": "need payment",
            "payment_data": "0",
            "next_payment_date": '0',
            "payment_id": 'no'
        }, 'user-refresh')
    }
    //
    setTimeout(async () => {
        if (next_payment_date.value === formattedToday) {
            await makeUserRefreshByUserId()
            await setIdAndEmailState()
        } else {
            // everything is okay
        }
    })
}