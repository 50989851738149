<template>
    <div class="w-100-flex">
        <div class="test-div mg-t-100">
          <h2>Настройка профиля и оплаты функционала клонера каналов</h2>

          <div class="usual-border mg-t-b-1 p-all-1">
            <div>
              <input type="text" v-model="tgApiKey">

              <button @click="updateUserTgApi">Изменить ключ tg_api</button>

              <p>current telegram bot token: {{ tgApi }}</p>
            </div>

            <div class="error-text" v-if="['no tg api', ''].includes(tgApi)">Не указан ключ tg_api</div>
          </div>

          <div class="usual-border mg-t-b-1 p-all-1" v-if="!loading">
            <div>
              <div>
                <input type="text" v-model="newSourceChannel" placeholder="channelName"/>
                <p v-if="isDev">примеры: funnyitmemes38, funnyitmemes38again, notariatirk, lobbywork, poleznayarabotavsegda</p>
                <p v-if="isDev" class="p-all-0-1" @click="() => {
                  sourceChannelsArr = ['funnyitmemes38', 'funnyitmemes38again', 'notariatirk', 'lobbywork', 'poleznayarabotavsegda']
                  saveSourceChannelsArr()
                }">fast test</p>
                <button @click="addNewSourceChannel" v-if="sourceChannelsArr.length < 5">Добавить источник копирования - канал откуда будем копировать</button>

                <div class="usual-border mg-t-b-1" v-for="sourceChannel in sourceChannelsArr" :key="sourceChannel">
                  <div class="flex-between flex-middle">
                    <div class="p-all-1">
                      <h3>{{ sourceChannel }}</h3>

                      <div v-if="!firstPayment && payment_data[sourceChannel] !== undefined" class="flex flex-column">
                        <button v-if="readyToPay[sourceChannel] === undefined" @click="payForServiceOneItem(sourceChannel)">Обновить период оплаты для канала</button>
                        <button @click="removeSourceChannel(sourceChannel)" class="bad-button">Убрать канал из списка чтобы освободить ячейку (действие нельзя будет отменить)</button><br>
                        <button v-if="readyToPay[sourceChannel] === true" @click="checkPaymentStatus(sourceChannel)">Check Payment Status</button>
                      </div>

                      <div v-if="!firstPayment && payment_data[sourceChannel] === undefined">
                        <button v-if="readyToPay[sourceChannel] === undefined" @click="payForServiceOneItem(sourceChannel)">Оплатить</button>

                        <button v-if="readyToPay[sourceChannel] === true" @click="checkPaymentStatus(sourceChannel)">Check Payment Status</button>
                      </div>
                    </div>
                    <i class="fa fa-times mg-a-1 cursor-hover" aria-hidden="true" v-if="firstPayment || payment_data[sourceChannel] === undefined" @click="filterChannelsSource(sourceChannel)"></i>
                  </div>
                </div>

                <div class="error-text" v-if="+channels_source === 0">Не сохранены или не указаны источники каналов откуда будете копировать (всего разрешено 5 каналов-источников)</div>
              </div>

              <button v-if="isDev" @click="saveSourceChannelsArr(true)">refresh</button>
            </div>
          </div>

          <div class="usual-border mg-t-b-1 p-all-1">
            <div v-if="to_copy_in_channel_id === 0">
              <input type="number" placeholder="0" v-model="toCopyInChannelId">
              <button @click="updateUserToCopyInChannelId">Добавить id канала куда будете копировать посты</button>

              <div class="error-text" v-if="to_copy_in_channel_id === 0">Не указан id канала куда будете копировать</div>
            </div>

            <template v-if="to_copy_in_channel_id !== 0">
                <h3>current to_copy_in_channel_id:</h3>

                <p>current to_copy_in_channel_id: {{ to_copy_in_channel_id }}</p>
            </template>

            <button @click="updateUserToCopyInChannelId(true)">refresh</button>
          </div>

          <div
              v-if="client_payment_status === 'need payment' || client_payment_status !== 'шекели уплачены' || next_payment_date === '0'"
              class="bad-border mg-t-b-1 p-all-1"
          >
            <span class="error-text">Требуется оплата функционала клонера каналов</span>

            <div class="flex flex-column w-fit-content mg-t-b-1">
              <button
                  v-if="tgApi !== '' && (channels_source.length > 0 && +channels_source !== 0) && to_copy_in_channel_id !== 0 && hidePaymentBtn === false"
                  @click="payForServiceFirstTime"
              >
                Оплатить
              </button>

              <span v-else class="error-text">Заполните все поля перед тем как осуществить оплату</span>

              <button v-if="payment_id !== 'no'" @click="checkPaymentStatus(false)">Check Payment Status</button>
            </div>

            <p>Payment id: {{payment_id}}</p>
            <p>Статус платежа: {{client_payment_status}}</p>
          </div>

          <div
              v-if="payment_data !== '0' && client_payment_status === 'шекели уплачены' && next_payment_date !== '0'"
              class="nice-border mg-t-b-1 p-all-1"
          >
            <div>
              <p v-for="(data, channel) in payment_data">
                дата следующего платежа: {{data}} | канал {{channel}}
              </p>
            </div>
            <p v-if="isDev">{{ next_payment_date }} - дата следующей оплаты</p>
          </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {API} from "@/services/API";
import {useAuthState} from "@/components/auth/authState";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import {showModal} from "@/layout/modalWindowsLogic/modalWindowFns";
import {useMainStore} from "@/store/mainStore";
import {setIdAndEmailState} from "@/components/auth/authFns";

defineOptions({
    name: 'profilesPage'
})

const firstPayment = ref(true)

const tgApiKey = ref("")
const newSourceChannel = ref("")
const sourceChannelsArr = ref([])
const toCopyInChannelId = ref(0)

const authState = useAuthState()
const mainStore = useMainStore()
const userId = computed(() => authState.id)
const tgApi = computed(() => authState.tg_api)

const channels_source = computed(() => authState.channels_source)
const to_copy_in_channel_id = computed(() => authState.to_copy_in_channel_id)
const client_payment_status = computed(() => authState.client_payment_status)
const payment_data = computed(() => authState.payment_data)
const next_payment_date = computed(() => authState.next_payment_date)

const isDev = computed(() => mainStore.isDev)
const payment_id = computed(() => mainStore.paymentId)

const filterChannelsSource = (channel) => {
  let value = channels_source.value.filter(i => i !== channel)
  sourceChannelsArr.value = sourceChannelsArr.value.filter(i => i !== channel)
  authState.setChannelsSourceState(value)

  saveSourceChannelsArr()
}

const loading = ref(true)

setTimeout(() => {
  if (channels_source.value !== 0) sourceChannelsArr.value = channels_source.value
  if (channels_source.value === 0) sourceChannelsArr.value = []

  if (next_payment_date.value !== '0'){
    firstPayment.value = false
  }

  loading.value = false
}, 2000)

let p_data = {}

const readyToPay = ref({})
const hidePaymentBtn = ref(false)

const payForServiceOneItem = async (value) => {
    let data = {}

    data[value] = ''

    let res = await API(false, 'youkassa_test','some-module', 'POST', {
        "product_id": 1,
        "user_id": userId.value,
        "payment_data": JSON.stringify(data),
    }, `youkassa-test`)

    await uCassaPaymentIdLogic(res)

    readyToPay.value[value] = true
}

const payForServiceFirstTime = async () => {
    sourceChannelsArr.value.forEach(i => {
      p_data[i] = ''
    })

    let res = await API(false, 'youkassa_test','some-module', 'POST', {
        "product_id": sourceChannelsArr.value.length,
        "user_id": userId.value,
        "payment_data": JSON.stringify(p_data)
    }, `youkassa-test`)
  
    await uCassaPaymentIdLogic(res)

    hidePaymentBtn.value= true
}

const uCassaPaymentIdLogic = async (res) => {
    await saveSourceChannelsArr()

    window.open(res.url, '_blank');
    mainStore.setPaymentId(res.id)
}

const checkPaymentStatus = async (sChannel = false) => {
    let res

    if (sChannel){
      res = await API(false, 'youkassa_testtwo','some-module', 'POST', {
          "payment_id": payment_id.value,
          "one_item": sChannel,
          "user_id": userId.value,
      }, `youkassa-testtwo`)
    } else {
      res = await API(false, 'youkassa_testtwo','some-module', 'POST', {
          "payment_id": payment_id.value,
          "payment_data": JSON.stringify(p_data)
      }, `youkassa-testtwo`)
    }

    if (res === 'succeeded'){
      authState.setClientPaymentStatusState('шекели уплачены')
      useModalWindowsStore().setSuccessStatus({text: 'Все успешно оплачено! Теперь обязательно обновите страницу!'})
      showModal('success', useModalWindowsStore())
      await setIdAndEmailState()
    }
}

const updateUserTgApi = async () => {
    let res = await API(true, 'update_user_tg_api','users', 'PUT', {
        "tg_api": tgApiKey.value,
    }, `update-user-tg-api-key/${userId.value}`)

    authState.setTgApiState(tgApiKey.value)
}

const updateUserToCopyInChannelId = async (refresh = false) => {
    let value = toCopyInChannelId.value
    if (refresh === true) value = 0

    let res = await API(true, 'update_to_copy_in_channel_id','users', 'PUT', {
        "to_copy_in_channel_id": value,
    }, `update-user-to-copy-in-channel-id/${userId.value}`)

    authState.setToCopyInChannelIdState(value)
}

const saveSourceChannelsArr = async (refresh = false) => {
    let channels_source_value

    if (sourceChannelsArr.value.length === 0){
      channels_source_value = '0'
    } else {
      channels_source_value = JSON.stringify(sourceChannelsArr.value)
    }

    if (refresh === true){
      channels_source_value = JSON.stringify(0)
      sourceChannelsArr.value = []
    }

    // todo - same code
    await API(true, 'update_user_channels_source','users', 'PUT', {
        "channels_source": channels_source_value,
    }, `update-user-channels-source/${userId.value}`)

    if (refresh === true){
      authState.setChannelsSourceState('0')
    } else {
      authState.setChannelsSourceState(sourceChannelsArr.value)
    }
}

const checkIfChannelWorks  = async (channelToTest) => {
  let res = await API(false, 'getRawDataFromRequest', 'some-module', 'POST', {
    // sourceChannel: 'work_init',
    sourceChannel: channelToTest,
    infiniteChannelWatching: false,
    postLimit: 0,
    userId: userId.value
  }, 'getRawDataFromRequest')

  if (isDev) console.log(res)

  let channelDoesNotExistStr = `If you have <strong>Telegram</strong>, you can contact <a class="tgme_username_link" href="tg://resolve?domain=${channelToTest}">`

  let isChannelExists = (res.includes(channelDoesNotExistStr) === false) // work_init_init_test_abvgd - в результате будет false так как канал не существует
  let isChannelValidToCLone = res.includes("tgme_main") // если как work_init то будет false

  return isChannelValidToCLone && isChannelExists
}

const removeSourceChannel = async (channels_source_value) =>{
  filterChannelsSource(channels_source_value)

  // todo - same code
  await API(true, 'update_user_channels_source','users', 'PUT', {
      "channels_source": JSON.stringify(sourceChannelsArr.value),
  }, `update-user-channels-source/${userId.value}`)

  // update payment_data
  delete payment_data.value[channels_source_value]

  // update next_payment_date
  const dates = []

  for (const [key, value] of Object.entries(payment_data.value)) {
    dates.push(value)
  }

  let next_payment_date_value

  if (Object.keys(payment_data.value).length !== 0){
    // Convert the date strings to Date objects
    next_payment_date_value = dates.reduce((latest, date) => {
      const current = new Date(date);
      return current > latest ? current : latest;
    }, new Date(dates[0]));
  }

  if (Object.keys(payment_data.value).length === 0){
    await updateClientPaymentStatus(true)
    next_payment_date_value = '0'
    payment_data.value = '0'
  }

  authState.setDaysToNextPaymentState(next_payment_date_value)

  await updatePaymentData()
  await updateNextPaymentDate()
}

// todo - sameSame
const updatePaymentData = async () => {
  // before query - update payment_data on front

  let res = await API(true, 'update_user_payment_data','users', 'PUT', {
      "payment_data": JSON.stringify(payment_data.value),
  }, `update-user-payment-data/${userId.value}`)

  if (isDev) console.log(res, 'payment_data updated')
}

const updateClientPaymentStatus = async (needPayment) => {
  let client_payment_status = 'шекели уплачены'

  if (needPayment){
    client_payment_status = 'need payment'
    authState.setClientPaymentStatusState('need payment')
  }

  let res = await API(true, 'update_client_payment_status','users', 'PUT', {
      "client_payment_status": client_payment_status,
  }, `update-user-client-payment-status/${userId.value}`)

  if (isDev) console.log(res, 'client_payment_status updated')
}

const updateNextPaymentDate = async () => {
  // before query - update next_payment_date on front

  let res = await API(true, 'update_user_next_payment_date','users', 'PUT', {
      "next_payment_date": next_payment_date.value,
  }, `update-user-next-payment-date/${userId.value}`)

  if (isDev) console.log(res, 'next_payment_date updated')
}

const addNewSourceChannel = async () => {
  if (sourceChannelsArr.value.some(i => i === newSourceChannel.value)){
    useModalWindowsStore().setErrorStatus({text: 'Канал уже есть в списке!'})
    showModal('error', useModalWindowsStore())
    return
  }

  if (await checkIfChannelWorks(newSourceChannel.value) === true){
    sourceChannelsArr.value.push(newSourceChannel.value)
    authState.setChannelsSourceState(sourceChannelsArr)
    await saveSourceChannelsArr()
    return
  }

  if (await checkIfChannelWorks(newSourceChannel.value) === false){
    useModalWindowsStore().setErrorStatus({text: 'Канал либо не существует либо не подходит для клонирования'})
    showModal('error', useModalWindowsStore())
  }
}
</script>

<style scoped>

</style>
