import axios from "axios";
import { computed } from "vue";
import { useAuthState } from "@/components/auth/authState";

const SERVER_API_ADDRESS = process.env.VUE_APP_SERVER_API_ADDRESS;

export const API = async (isCrud, action, module, method, requestBody, directRoute) => {
  const headersTypes = {application: {"Accept": "application/json", "Content-Type": "application/json"}};

  // ******************************
  // query params в такой же последовательности как и в API на стороне BACK
  let crudActions = {
    users: {
      POST: ["create_user", "make_user_default", "make_user_refresh"],
      DELETE: {
        delete_user_by_id: {
          pathParams: ["user_id"]
        },
        delete_user_by_email: {
          pathParams: ["email"]
        }
      },
      UPLOAD: {},
      GET: {
        get_all_users: {
          pathParams: []
        },
        get_user_by_id: {
          pathParams: ["user_id"]
        },
        get_user_by_email: {
          pathParams: ["email"]
        },
      },
      PUT: {
        update_all_user_data: {
          pathParams: ["user_id"]
        },
        update_user_email: {
          pathParams: ["user_id"]
        },
        update_user_tg_api: {
          pathParams: ["user_id"]
        },
        update_user_channels_source: {
          pathParams: ["user_id"]
        },
        update_to_copy_in_channel_id: {
          pathParams: ["user_id"]
        },
        update_user_payment_data: {
          pathParams: ["user_id"]
        },
        update_user_next_payment_date: {
          pathParams: ["user_id"]
        }
      }
    }
  };

  if (isCrud) {
    // здесь просто проверка на crud actions
    if (method === "POST" && !crudActions[module].POST[action] === undefined) return;
    if (method === "DELETE" && !crudActions[module].DELETE[action] === undefined) return;
    if (method === "UPLOAD" && !crudActions[module].UPLOAD[action] === undefined) return;
    if (method === "GET" && !crudActions[module].GET[action] === undefined) return;
    if (method === "PUT" && !crudActions[module].PUT[action] === undefined) return;
  }

  let route;

  if (directRoute) route = directRoute;

  let res = await sendRequest();

  function axiosErrorFn(error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
  }

  return res?.data;

  async function sendRequest() {
    let link = SERVER_API_ADDRESS + route;

    if (method === "POST") return await axios.post(link, JSON.stringify(requestBody), {headers: headersTypes.application}).catch((error) => axiosErrorFn(error));
    if (method === "DELETE") return await axios.delete(link, { headers: headersTypes.application }).catch((error) => axiosErrorFn(error));
    if (method === "UPLOAD") return null;
    if (method === "GET") return await axios.get(link, { headers: headersTypes.application }).catch((error) => axiosErrorFn(error));
    if (method === "PUT") return await axios.put(link, JSON.stringify(requestBody), {headers: headersTypes.application}).catch((error) => axiosErrorFn(error));
  }
};

// axios({
//     method: 'post',
//     url: 'http://127.0.0.1:8000/users',
//     data: JSON.stringify({
//         "email": email.value,
//         "user_id": email.value,
//         "ref_link": '',
//         "last_online": '',
//         "ref_id": email.value,
//     }),
//     headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json'
//     },
// })
//     .then(response => {
//         console.log(response);
//     })
//     .catch(error => {
//         console.error(error);
//     });
