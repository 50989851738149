<template>
    <div>
        <form action="" @submit="register">
            <input placeholder="email" v-model="email" type="email"/>
            <input placeholder="password" v-model="password" type="password"/>
            <input placeholder="copy-password" v-model="copyPassword" type="password"/>
            <button class="auth-btn" type="button" @click="register">Создать аккаунт</button>

            <p class="another-modal" @click="closeOneOpenOtherFn('register', 'login')">Есть аккаунт? Войти по существующему логину и паролю</p>

            <p class="error-text">{{error && error}}</p>
        </form>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {auth} from "@/firebase";
import {createUserWithEmailAndPassword} from "firebase/auth"
import {closeModalWindow, closeOneOpenOther} from "@/layout/modalWindowsLogic/modalWindowFns";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import {API} from "@/services/API";
import {setIdAndEmailState} from "@/components/auth/authFns";

defineOptions({
    name: 'SignUp'
})

let email = ref('')
let password = ref('')
let copyPassword = ref('')

let error = ref('')

const modalWindowsStore = useModalWindowsStore()

// async
const register = (e) => {
    e.preventDefault()

    if (copyPassword.value !== password.value){
        error.value = 'Passwords did not match!'
        return
    }

    // await
    createUserWithEmailAndPassword(auth, email.value, password.value)
        .then((user) => {
            console.log(user)

            registerUserToBackend(email.value, password.value)

            error.value = ''
            copyPassword.value = ''
            password.value = ''
            email.value = ''

            closeModalWindow('register', modalWindowsStore)
        }).catch((e) => {
            if (e.message !== undefined) error.value = e.message
            else error.value = 'Возможно такой аккаунт уже есть или иная ошибка!'
        })
}

const closeOneOpenOtherFn = (closeModal, openModal) => {
    closeOneOpenOther(closeModal, openModal, useModalWindowsStore())
}

const registerUserToBackend = async (email, psw) => {
    // логика регистрации

    // let tokenData = await API(true, 'create_user','users', 'POST', {

    // console.log('register')

    await API(true, 'create_user','users', 'POST', {
        "email": email,
        "user_id": email,
        "ref_link": '',
        "last_online": '',
        "ref_id": email,
        "password": psw,
        "token": '',
        "tg_api": 'no tg api',

        "channels_source": '0',
        "to_copy_in_channel_id": 0,
        "client_payment_status": "need payment",
        "payment_data": "0",
        "next_payment_date": '0',
        "payment_id": 'no'
    }, 'users')

    // localStorage.setItem("chatClonerToken", tokenData.token);

    await setIdAndEmailState()
}
</script>

<style lang="scss" scoped>

</style>
