<template>
    <div class="test-div mg-t-100">
      <h2>Простое клонирование</h2>

      <br>

      В разработке

      <!-- вынести потом отдельно компонентом -->
<!--      <div class="usual-border mg-t-b-1 p-all-1">-->
<!--        <h4>откуда клонируем посты (имя канала)</h4>-->
<!--        <h4 v-if="isDev">примеры: funnyitmemes38, funnyitmemes38again, notariatirk, lobbywork, poleznayarabotavsegda</h4>-->

<!--        <template v-for="source in channels_source" :key="source">-->
<!--          <p><input type="radio" :value="source" v-model="chatClonerLogicPayload.sourceChannel"/> {{source}}</p>-->
<!--        </template>-->
<!--      </div>-->

<!--      &lt;!&ndash; вынести потом отдельно компонентом &ndash;&gt;-->
<!--      <div class="usual-border mg-t-b-1 p-all-1">-->
<!--        <h4>куда клонируем посты (id канала)</h4>-->
<!--        <h4 v-if="isDev">пример 770846692</h4>-->
<!--        <input type="text" :placeholder="to_copy_in_channel_id" disabled>-->
<!--      </div>-->

<!--      &lt;!&ndash; вынести потом отдельно компонентом &ndash;&gt;-->
<!--      <div class="usual-border mg-t-b-1 p-all-1">-->
<!--        <input type="number" v-model="chatClonerLogicPayload.postLimit"> - лимит постов, которые собираемся клонировать-->
<!--      </div>-->

<!--      <div class="usual-border mg-t-b-1 p-all-1">-->
<!--        <input type="checkbox" v-model="chatClonerLogicPayload.withImages"> - брать ли изображения из постов или нет-->
<!--      </div>-->

<!--      <div class="usual-border mg-t-b-1 p-all-1">-->
<!--        <p>Date-range settings</p>-->

<!--        <br>-->

<!--        <input type="checkbox" v-model="chatClonerLogicPayload.dateRange.withTime" disabled> - прописывать ли время в дате или диапозоне дат (пока отключаем)<br><br>-->

<!--        <select v-model="chatClonerLogicPayload.dateRange.type">-->
<!--          <option value="all">all</option>-->
<!--          <option value="byDate">byDate</option>-->
<!--          <option value="byRange">byRange</option>-->
<!--        </select>-->

<!--        <template v-if="chatClonerLogicPayload.dateRange.type === 'byDate'">-->
<!--          <input-->
<!--            :type="chatClonerLogicPayload.dateRange.withTime ? 'datetime-local' : 'date'"-->
<!--            min="2015-01-01T00:00"-->
<!--            max="2025-12-31T23:59"-->
<!--            v-model="chatClonerLogicPayload.dateRange.value"-->
<!--          />-->
<!--        </template>-->

<!--        <template v-if="chatClonerLogicPayload.dateRange.type === 'byRange'">-->
<!--          ОТ-->
<!--          <input-->
<!--            :type="chatClonerLogicPayload.dateRange.withTime ? 'datetime-local' : 'date'"-->
<!--            min="2015-01-01T00:00"-->
<!--            max="2025-12-31T23:59"-->
<!--            v-model="dateRangeValues.from"-->
<!--          />-->
<!--          ДО-->
<!--          <input-->
<!--            :type="chatClonerLogicPayload.dateRange.withTime ? 'datetime-local' : 'date'"-->
<!--            min="2015-01-01T00:00"-->
<!--            max="2025-12-31T23:59"-->
<!--            v-model="dateRangeValues.to"-->
<!--          />-->
<!--        </template>-->
<!--      </div>-->

<!--      <div class="usual-border mg-t-b-1 p-all-1">{{chatClonerLogicPayload}}</div>-->

<!--      <button @click="chatClonerLogicInit">Send</button>-->
    </div>
</template>

<script setup>
import {chatClonerLogic} from "@/services/chatClonerLogic";
import {computed, ref, watch} from "vue";
import {showModal} from "@/layout/modalWindowsLogic/modalWindowFns";
import {useModalWindowsStore} from "@/layout/modalWindowsLogic/modalWindowsStore";
import {useAuthState} from "@/components/auth/authState";
import {useMainStore} from "@/store/mainStore";

defineOptions({
    name: 'basecloner'
})

const modalWindowsStore = useModalWindowsStore()
const authState = useAuthState()
const mainStore = useMainStore()

const channels_source = computed(() => authState.channels_source)
const to_copy_in_channel_id = computed(() => authState.to_copy_in_channel_id)

const userId = computed(() => authState.id)

const chatClonerLogicPayload = ref({
    sourceChannel: '',
    toCopyInChannel: '',
    dateRange: {
      type: 'all', // all, byDate, byRange
      value: '',
      withTime: false
    },
    postLimit: 0,
    withImages: true,
    tgApi: '',
    userId: 0
})

chatClonerLogicPayload.value.toCopyInChannel = to_copy_in_channel_id.value
chatClonerLogicPayload.value.userId = userId.value

const dateRangeValues = ref({from: '', to: ''})
const dateRangeComputed = computed(() => {return dateRangeValues.value.from + '###' + dateRangeValues.value.to})
const isDev = computed(() => mainStore.isDev)

watch(
    () => chatClonerLogicPayload.value.dateRange.type,
    (newValue, oldValue) => {
      if (newValue !== oldValue) chatClonerLogicPayload.value.dateRange.value = ''
    }
)

watch(
    () => dateRangeComputed.value,
    (newValue) => {chatClonerLogicPayload.value.dateRange.value = newValue}
)

const tgApi = computed(() => authState.tg_api)
chatClonerLogicPayload.value.tgApi = tgApi.value

const chatClonerLogicInit = async (deleteInterval = false, item=null) => {
    if (!channels_source.value.some(i => i === chatClonerLogicPayload.value.sourceChannel)){
      useModalWindowsStore().setErrorStatus({text: 'Можно выбрать только доступные каналы!'})
      showModal('error', useModalWindowsStore())
      return
    }

    if (chatClonerLogicPayload.value.dateRange.type === 'byRange' && dateRangeComputed.value.split('###').length !== 3){
      useModalWindowsStore().setErrorStatus({text: 'Введи нормально date range!'})
      showModal('error', useModalWindowsStore())
      return
    }

    // убираем ссылочную зависимость
    let res = await chatClonerLogic(JSON.parse(JSON.stringify(chatClonerLogicPayload.value)), false, {
      deleteInterval: false,
      executionInterval: 0
    })

    if (res?.error) {
      modalWindowsStore.setErrorStatus({text: res.error})
      showModal('error', useModalWindowsStore())
    } else {
      console.log(res, 'какой-то res!')
    }
}
</script>

<style scoped>

</style>
